import React from "react";
import Navbar from "comps/navbar";
import ProjectCard from "comps/project_card";
import labellerimg from "resources/imgs/labeller.png";
import displacementimg from "resources/imgs/Displacement.png";
import pceimg from "resources/imgs/PCE.png";
import "./index.css";

const Projects = () => {
  return (
    <div>
      <Navbar route="Projects" />

      <div className="content-wrapper center">
        <h1>Projects</h1>

        <div className="project-card-container">
          <ProjectCard
            imgsrc={displacementimg}
            imgtxt="Project Banner"
            title="Displacement"
            highlights={[
              "Full Stack",
              "Team Project",
              "Unity 3D",
              "Compression",
              "Computer Vision",
              "Multiplayer",
            ]}
            linksrc="https://formblu.com/displacement-blog"
            linktxt="Dev log"
          >
            As the lead developer for Formblu's Displacement Prototyping
            project, I focused on crafting a dynamic, user-friendly platform
            that integrates advanced design visualization with seamless
            interactivity. Utilizing cutting-edge web technologies, I
            implemented efficient algorithms for real-time displacement mapping
            and ensured smooth, responsive rendering for complex geometries.
            This project exemplifies my commitment to blending technical
            innovation with intuitive user experiences.
          </ProjectCard>

          <ProjectCard
            imgsrc={pceimg}
            imgtxt="Project Banner"
            title="Procedural Space Exploration"
            highlights={[
              "Godot C#",
              "Pixel Shaders",
              "Infinite Universe",
              "JIT Generation",
            ]}
            linksrc="https://github.com/thetornadotitan/godot-procedural-space-generation"
            linktxt="Github Repo"
          >
            This is a 2D project focused on creating procedurally generated
            space environments. Using Godot, this project generates star systems
            and planetary bodies. The goal was to build a system capable of
            producing vast 2D space scenes. By implementing procedural
            generation techniques, this project delivers a randomly generated
            universe for users to explore. Each run will present a different
            universe. However, during a run, the universe remains persistent,
            even when regenerated, by revisiting locations.
          </ProjectCard>

          <ProjectCard
            imgsrc="https://user-images.githubusercontent.com/22167677/147900037-01cf57b8-8a77-4b57-9aab-89c4854097fd.png"
            imgtxt="Project Banner"
            title="Media Tracker"
            highlights={[
              "Purpose Built",
              "Daily Use",
              "Electron",
              "React",
              "Node JS",
              "JavaScript",
            ]}
            linksrc="https://github.com/thetornadotitan/media_tracker_react_electron"
            linktxt="Github Repo"
          >
            This media tracker holds significant personal value, having been
            created to monitor both watched and unwatched media. While I was
            aware of media servers like Plex and Jellyfin, I didn't feel the
            need for a full-fledged media server, finding it excessive for my
            needs. My goal was to gain hands-on experience with React and
            Electron by developing my own lightweight, dedicated media tracking
            solution. Over the past three years, this application has been an
            essential part of my daily life, serving as a testament to my growth
            and commitment to self-driven development. It stands as a source of
            personal pride, reflecting both my technical journey and the value
            it has added to my media consumption.
          </ProjectCard>

          <ProjectCard
            imgsrc="https://steamuserimages-a.akamaihd.net/ugc/888756449958422283/E4B7235AF650BA904118B894765A62A9F4D7D2CC/?imw=637&imh=358&ima=fit&impolicy=Letterbox&imcolor=%23000000&letterbox=true"
            imgtxt="Project Banner"
            title="Missle Defence - Wallpaper Engine"
            highlights={[
              "JavaScript",
              "Wallpaper Engine",
              "Steam Workshop",
              "211 Subscribers",
            ]}
            linksrc="https://steamcommunity.com/sharedfiles/filedetails/?id=1150502306"
            linktxt="Steam Workshop Page"
          >
            This project was created for Wallpaper Engine, an application that
            enables dynamic, moving desktop backgrounds. My initial fascination
            with Wallpaper Engine centered on creating personalized, interactive
            backgrounds. My first concept was a self-playing game inspired by
            Missile Defense, utilizing steering behaviors to control the
            movement of rockets. I implemented this idea using JavaScript, and
            it became my desktop background for a considerable amount of time.
            This project reflects my interest in blending interactive elements
            with desktop customization, showcasing both creativity and technical
            experimentation.
          </ProjectCard>

          <ProjectCard
            imgsrc={labellerimg}
            imgtxt="Project Banner"
            title="Audio Tagging Software"
            highlights={[
              "C# - WPF",
              "Freelance Work",
              "Audio Tagging",
              "Custom Tool",
            ]}
          >
            This screencap showcases the result of my first freelance
            assignment. Unfortunately, a link to the code is not available as it
            remains proprietary to the contracting company. For this project, I
            utilized C# and WPF, alongside advanced audio libraries, to
            implement a system for tagging audio files with per-sample accuracy.
            This enhancement significantly improved the company’s workflows,
            enabling the integration of new tools to enhance accuracy and
            efficiency.
          </ProjectCard>
        </div>

        <hr />

        <h1>Publicly Released Games</h1>
        <div className="project-card-container">
          <ProjectCard
            imgsrc="https://img.itch.zone/aW1nLzk3NzcxMTMucG5n/original/VJxWaO.png"
            imgtxt="Game Banner"
            title="Stair Fall"
            highlights={[
              "First Game Jam",
              "Made in 2 days",
              "Collaboration",
              "6th place overall",
              "Godot Engine",
              "C#",
            ]}
            linksrc="https://thetornadotitan.itch.io/stair-fall"
            linktxt="Itch.io Page"
          >
            Embark on a thrilling gaming adventure centered around a character
            grappling with an overwhelming gravitational force. Endure the
            relentless pull of gravity for as long as you can, all while keeping
            a keen eye out for mischievous grandmas! Replenish your energy by
            devouring an array of delicious burgers and restore your health by
            collecting scattered hearts. Stay clear of all other obstacles to
            survive. Additionally, utilize elevators to escalate your levels and
            enhance your chances of success!
            <hr />
            Created within a 2 day timeframe for Pixel Jam 2 by Isaac Hisey and
            Justin Gilbert.
            <hr />
            Programming: Isaac Hisey
            <br />
            Art & Music: Justin Gilbert
            <br />
            Design/Concept: Justin Gilbert & Isaac Hisey
          </ProjectCard>

          <ProjectCard
            imgsrc="https://img.itch.zone/aW1nLzEzODI2Mjg4LnBuZw==/347x500/WJGXMW.png"
            imgtxt="Game Banner"
            title="Cryptomon"
            highlights={[
              "Second Game Jam",
              "Beat ~74% of entries",
              "620 of ~2.5k entries",
              "Collaboration",
              "Godot Engine",
              "C#",
            ]}
            linksrc="https://thetornadotitan.itch.io/cryptomon"
            linktxt="Itch.io Page"
          >
            Make money by taking care of your Cryptomon. Survive by meeting your
            own and your Cryptomons' needs.
            <br />
            <br />
            Your character will die if their health reaches 0. If other players'
            needs are neglected, health will drop more rapidly.
            <br />
            <br />
            Crypotmon produce more money when they are well taken care of.
            Cryptomon will die if you let their health reach 0. If other needs
            of Cryptomon are neglected, health will drop more
            <br />
            <br />
            The game runs on a tick rate of every 10 seconds. You can take one
            action for yourself and one for each of the Crytomon you own - per
            tick. Survive as long as possible. Thrive if
            <br />
            <br />
            There are 7 Cryptomon and 4 Cryptovices, 28 combinations! Can you
            get them all?
          </ProjectCard>
        </div>

        <hr />

        <h1>Game Modding</h1>
        <div className="project-card-container">
          <ProjectCard
            imgsrc="https://staticdelivery.nexusmods.com/mods/3174/images/3503/3503-1639844417-1914924311.jpeg"
            imgtxt="Mod Banner"
            title="Kaoses Tweaks - Bannerlord"
            highlights={[
              "Endorsements: 2,053",
              "Unique DLs: 70,300",
              "Total DLs: 128,109",
              "Total views: 384,612",
            ]}
            linksrc="https://www.nexusmods.com/mountandblade2bannerlord/mods/3503?tab=desc"
            linktxt="Nexus Mods"
          >
            I took a role in the Bannerlord mod community, focusing on the
            revival of a popular mod to align with the latest Mount & Blade II:
            Bannerlord version. My collaboration with developers led to the
            incorporation of new features and fixes. The documentation of my
            contributions on the GitHub repository provides a tangible record of
            my technical input and dedication to community-driven initiatives.
            This experience underscores my adaptability and commitment to
            collaborative efforts within the game modding community.
            <hr />
            A tip of the hat too: <br />
            Arcanak, CollinHerber, Ocu87, Igor-Rakhmanov, cburch824 for their
            contributions
            <hr />
            Big shout-out to: <br />
            MinhazMurks who took over to keep the ball rolling until the
            original developer came back.
          </ProjectCard>

          <ProjectCard
            imgsrc="https://staticdelivery.nexusmods.com/mods/110/images/14267-1-1333443582.jpg"
            imgtxt="Mod Banner"
            title="More and Indefinite Mining - Skyrim"
            highlights={[
              "Endorsements: 2,059",
              "Unique DLs: 21,047",
              "Total DLs: 47,018",
              "Total views: 153,066",
            ]}
            linksrc="https://www.nexusmods.com/skyrim/mods/14267?tab=description"
            linktxt="Nexus Mods"
          >
            This Skyrim mod enhances the ore mining experience, allowing players
            to mine indefinitely at an accelerated rate. Designed to be
            compatible with the Dragonborn DLC through the Gyldenhul Dragonborn
            Mining Fix by SeanGamer, the mod draws inspiration from similar
            concepts in other mods but features a script entirely of my
            creation, utilizing Bethesda source files from the Creation Kit. The
            installation process is straightforward, either manual or through
            Nexus Mod Manager. A note: if you've previously entered a mine or
            mined an ore vein, the new script may require entering a new mine,
            starting a new game, or waiting for ore veins to respawn to take
            effect, as highlighted by community input on the Nexus Mods page.
          </ProjectCard>

          <ProjectCard
            imgsrc="https://staticdelivery.nexusmods.com/mods/101/images/35810-4-1290806387.jpg"
            imgtxt="Mod Banner"
            title="Isaacs Crafting Mod - Oblivion"
            highlights={[
              "One of my first mods",
              "Originally uploaded in 2010!",
            ]}
            linksrc="https://www.nexusmods.com/oblivion/mods/35810?tab=description"
            linktxt="Nexus Mods"
          >
            This Skyrim mod enhances the ore mining experience, allowing players
            to mine indefinitely at an accelerated rate. Designed to be
            compatible with the Dragonborn DLC through the Gyldenhul Dragonborn
            Mining Fix by SeanGamer, the mod draws inspiration from similar
            concepts in other mods but features a script entirely of my
            creation, utilizing Bethesda source files from the Creation Kit. The
            installation process is straightforward, either manual or through
            Nexus Mod Manager. A note: if you've previously entered a mine or
            mined an ore vein, the new script may require entering a new mine,
            starting a new game, or waiting for ore veins to respawn to take
            effect, as highlighted by community input on the Nexus Mods page.
          </ProjectCard>
        </div>
      </div>
    </div>
  );
};

export default Projects;
