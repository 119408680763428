/* eslint-disable jsx-a11y/anchor-has-content */

import React from "react";
import "./navbar.css";
import logo from "resources/logo512.png";

class Navbar extends React.Component {
  constructor(props) {
    super();
    this.state = {
      aIdx: -1,
      routes: [
        { name: "Home", route: "/#" },
        { name: "About", route: "/#about" },
        { name: "Projects", route: "/#projects" },
      ],
    };
    let routeIdx = -1;
    this.state.routes.forEach((r, i) => {
      if (r.name === props.route) routeIdx = i;
    });
    this.state.aIdx = routeIdx;
  }

  render() {
    let routes = [];
    this.state.routes.forEach((s, i) => {
      const className = i === this.state.aIdx ? "active-link" : "";
      routes.push(
        <a href={s.route} draggable="false" className={className} key={i}>
          <div>{s.name}</div>
        </a>
      );
    });
    return (
      <div className="navbar">
        <div className="navbar-header">
          <img src={logo} alt="profile pic"></img>
          <h1>Isaac Hisey</h1>
          <div className="navbar-socials">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.linkedin.com/in/isaac-hisey/"
              className="fa fa-linkedin"
            ></a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://github.com/thetornadotitan"
              className="fa fa-github"
            ></a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.facebook.com/isaachisey"
              className="fa fa-facebook"
            ></a>
          </div>
        </div>
        <div className="navbar-subheader">
          Skilled Developer | Data Analyst | Solution-Oriented | Proactive
          Collaborator | Business Impact Focused
        </div>
        <div className="navbar-links">{routes}</div>
      </div>
    );
  }
}

export default Navbar;
